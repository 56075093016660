













// import MenuStore from "@/store/modules/menu-store";
import { Component, Vue } from "vue-property-decorator";
// import { getModule } from "vuex-module-decorators";

declare const APP_VERSION: string;
declare const APP_GIT_COMMIT_HASH: string;
declare const APP_GIT_COMMIT_DATE: string;

@Component
export default class About extends Vue {
  private version: string = APP_VERSION;
  private gitHash: string = APP_GIT_COMMIT_HASH;
  private gitDate: string = APP_GIT_COMMIT_DATE;

  // private menuStore: MenuStore = getModule(MenuStore);

  // public constructor() {
  //   super();
  //   this.menuStore.setSecondLevelMenuEnabled(false);
  // }
}
